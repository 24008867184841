var header = {
  element: document.getElementById('header'),
  hamburger: document.getElementById('hamburger'),
  menu: document.getElementById('menu'),
  init: function () {
    var $this = this;
    $this.hamburger.addEventListener('click', function () {
      $this.open();
    });
  },
  open: function () {
    this.element.classList.toggle('open');
    this.hamburger.classList.toggle('open');
    this.menu.classList.toggle('open');
    document.body.classList.toggle('active');
  }
};

header.init();
